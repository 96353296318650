

const clientRoutes = [
    {
        path: 'manage-user-client',
        name: 'manage-user-client',
        component: () => import('../views/clientAdmin/ManageUserClient.vue'),
        meta: {
            title: 'Banner Insite - Manage Users',
            permissions: ["system_admin", "client_admin", "admin", "manager", "user"]
        },
        sensitive: true,
        strict: true
    },
    {
        path: 'manage-site-client',
        name: 'manage-site-client',
        component: () => import('../views/clientAdmin/ManageSiteClient.vue'),
        meta: {
            title: 'Banner Insite - Manage Sites',
            permissions: ["system_admin", "client_admin", "admin", "manager", "user"]
        },
        sensitive: true,
        strict: true
    },
    {
        path: 'site-groups-client',
        name: 'site-groups-client',
        component: () => import('../views/clientAdmin/SiteGroupsClient.vue'),
        meta: {
            title: 'Banner Insite - Sites Groups',
            permissions: ["system_admin", "client_admin", "admin", "manager", "user"]
        },
        sensitive: true,
        strict: true
    },
    {
        path: 'manage-assets-client',
        name: 'manage-assets-client',
        component: () => import('../views/clientAdmin/ManageAssetsClient.vue'),
        meta: {
            title: 'Banner Insite - Manage Assets',
            permissions: ["system_admin", "client_admin", "admin", "manager", "user"]
        },
        sensitive: true,
        strict: true
    },
    {
        path: 'manage-images-client',
        name: 'manage-images-client',
        component: () => import('../views/clientAdmin/ManageImagesClient.vue'),
        meta: {
            title: 'Banner Insite - Manage Images',
            permissions: ["system_admin", "client_admin", "admin", "manager", "user"]
        },
        sensitive: true,
        strict: true
    },
    {
        path: 'manage-themes',
        name: 'manage-themes',
        component: () => import('../views/systemAdmin/ManageTheme.vue'),
        meta: {
            title: 'Banner Insite - Manage Themes',
            permissions: ['system_admin',"client_admin"]
        },
        sensitive: true,
        strict: true
    },
    
    
    ]
    
    export default clientRoutes